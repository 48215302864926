import iconMore from "../../assets/images/icon/icon_angle_right.svg";

import newsImg1 from "../../assets/images/img_news01.png";
import newsImg2 from "../../assets/images/img_news02.png";
import newsImg3 from "../../assets/images/img_news03.png";
import newsImg4 from "../../assets/images/img_news04.png";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function News() {
  const { t } = useTranslation();
  return (
    <section id="news" className="section">
      <div className="inner">
        <h2 className="main_title" data-aos="fade">
          News & Notice
        </h2>
        <div
          className="cont"
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
          data-aos-offset="-300"
        >
          <Link to="/" className="btn_more">
            READ MORE <img src={iconMore} alt="오른쪽 화살표 아이콘" />
          </Link>
          <div className="news_box_wrap">
            <div>
              <Link
                to="https://www.koreareport.co.kr/news/articleView.html?idxno=30963"
                target="_blank"
                className="box"
              >
                <div
                  className="box_img"
                  style={{
                    backgroundImage: `url(${newsImg3})`,
                  }}
                ></div>
                <div className="box_text">
                  <span className="date">2025. 01. 02</span>
                  <p className="text-overflow line">{t("news3")}</p>
                </div>
              </Link>
            </div>
            <div>
              <Link
                to="https://www.blockchaintoday.co.kr/news/articleView.html?idxno=47017"
                target="_blank"
                className="box"
              >
                <div
                  className="box_img"
                  style={{
                    backgroundImage: `url(${newsImg2})`,
                  }}
                ></div>
                <div className="box_text">
                  <span className="date">2024. 10. 01</span>
                  <p className="text-overflow line">{t("news2")}</p>
                </div>
              </Link>
            </div>
            <div>
              <Link
                to="https://www.tokenpost.kr/article-187109"
                className="box"
                target="_blank"
              >
                <div
                  className="box_img"
                  style={{
                    backgroundImage: `url(${newsImg1})`,
                  }}
                ></div>
                <div className="box_text">
                  <span className="date">2024. 07. 16</span>
                  <p className="text-overflow line">{t("news1")}</p>
                </div>
              </Link>
            </div>
            {/*<div>
              <a
                href="/"
                className="box"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <div
                  className="box_img"
                  style={{
                    backgroundImage: `url(${newsImg4})`,
                  }}
                ></div>
                <div className="box_text">
                  <span className="date">2024. 01. 20</span>
                  <p className="text-overflow line">{t("news4")}</p>
                </div>
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}
